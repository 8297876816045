import { Avatar, Button, Col, Container, Grid, Image, Row, Spacer, Text } from "@nextui-org/react";
import React from "react";
import EnumCard from "../components/EnumCard";
import TextCard from "../components/TextCard";
import { HashLink } from "react-router-hash-link";
import { FaChevronLeft, FaChevronUp, FaRegCommentDots } from "react-icons/fa";
import { MdAccessible, MdDesignServices } from "react-icons/md";
import { HiOutlineLightBulb } from "react-icons/hi";
import ExperienceProjectHero from "../components/ExperienceProjectHero";

export default function JustSnap() {
  return (
    <>
      <div>
        {/* Header */}
        <Container sm css={{ paddingTop: "15vh" }}>
          <Row>
            <HashLink to="/#top">
              <Avatar
                pointer
                color="$black"
                icon={<FaChevronLeft color="white" />}
                squared
                css={{ backgroundColor: "$primary", zIndex: "0" }}
              />
            </HashLink>
          </Row>
          <Spacer y={1} />
          <Row>
            <Col>
              <Text h1>JustSnap</Text>
              <Text h4 color="$gray">
                Responsive website and application provided easy to follow and reliable how-to guide
                for anythings.
              </Text>
            </Col>
          </Row>
          <Spacer y={2} />
          <Row>
            <Image
              alt=""
              src="/images/JustSnap/JustSnapThumbnail.png"
              width="100%"
              height="100%"
              css={{ borderRadius: "1rem" }}
            />
          </Row>
          <Spacer y={2} />
          <Grid.Container gap={1}>
            <Grid xs={12} sm={4}>
              <Container gap={0}>
                <Row>
                  <Col>
                    <Text h4>Duration:</Text>
                    <Spacer y={0.5} />
                    <Text css={{ fontFamily: "$primary" }}>October 2022</Text>
                  </Col>
                </Row>
              </Container>
            </Grid>
            <Grid xs={12} sm={4}>
              <Container gap={0}>
                <Row>
                  <Col>
                    <Text h4>Project info:</Text>
                    <Spacer y={0.5} />
                    <Text css={{ fontFamily: "$primary" }}>
                      Google UX Design Professional Certificate course prompt project
                    </Text>
                  </Col>
                </Row>
              </Container>
            </Grid>
            <Grid xs={12} sm={4}>
              <Container gap={0}>
                <Row>
                  <Col>
                    <Text h4>Tools:</Text>
                    <Spacer y={0.5} />

                    <Text css={{ fontFamily: "$primary" }}>Figma</Text>
                  </Col>
                </Row>
              </Container>
            </Grid>
          </Grid.Container>

          <Spacer y={2} />

          <Row>
            <Text h2>Overview</Text>
          </Row>
          <Spacer y={2} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              An application called JustSnap offers trustworthy how-to guides for everything that
              are simple to follow. Additionally offered were a responsive website variant and an
              application that users could use on tablet and desktop computers. The solutions are to
              write step-by-step instructions that are simple to follow and allow users to comment
              on their experiences to assist others in making decisions and ensure that the
              instructions are helpful.
            </Text>
          </Row>

          <Spacer y={2} />
          <Grid.Container gap={2}>
            <Grid xs={12} md={6}>
              <TextCard headline="Role" content="As a UX/UI designer of the project." />
            </Grid>
            <Grid xs={12} md={6}>
              <TextCard
                headline="Responsibilities"
                content="Conducting research surveys, information architecture, wireframing, low and high-fidelity prototyping, usability study, and design iteration."
              />
            </Grid>
          </Grid.Container>

          {/* Emphatize */}

          <Spacer y={2} />

          <Container lg gap={2}>
            {/* User Research */}
            <Row align="center">
              <Avatar
                color={""}
                icon={<FaRegCommentDots size="auto" color="white" />}
                size="xl"
                css={{ backgroundColor: "$green700" }}
              />
              <Spacer x={1} />
              <Col>
                <Text h5 color="$gray">
                  USER INSIGHTS
                </Text>
                <Text h2>Understanding the user</Text>
              </Col>
            </Row>

            <Spacer y={1} />
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              I've published a research survey with questions about background and experiences when
              people searching for how-to guides.
            </Text>
            <Spacer y={1} />
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              According to survey responses, the majority of user issues were some instructions is
              hard to follow and also reliability to select which how-to instruction that will be
              useful.
            </Text>

            <Spacer y={2} />

            <Text h4>Pain points</Text>

            <Spacer y={1} />

            <Grid.Container gap={2}>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="1"
                  content="Users answer a survey about their experiences that some how-to guide is not clear and hard to follow."
                  bgColor=""
                  header="Difficult to understand"
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="2"
                  content="Users answer a survey about their experiences that they are not sure which how-to guide is usable."
                  bgColor=""
                  header="Reliability"
                />
              </Grid>
            </Grid.Container>

            <Spacer y={1} />

            <Text h4>Persona</Text>

            <Image width="100%" height="100%" alt="" src="images/JustSnap/JustSnapPersona.png" />

            <Text h4>Doris's journey map</Text>

            <Spacer y={1} />

            <Image width="100%" height="100%" alt="" src="images/JustSnap/JourneyMap.png" />
          </Container>

          <Container sm>
            <Grid.Container gap={2} alignItems="center" css={{ rowGap: "3rem" }}>
              <Grid xs={12}>
                <Container fluid gap={0}>
                  <Row></Row>

                  <Row>
                    <Avatar
                      color={""}
                      icon={<HiOutlineLightBulb size="auto" color="white" />}
                      size="xl"
                      css={{ backgroundColor: "$yellow600" }}
                    />
                    <Spacer x={1} />
                    <Col>
                      <Text h5 color="$gray">
                        IDEATE
                      </Text>
                      <Text h1>Starting the design</Text>
                    </Col>
                  </Row>
                  <Spacer y={1} />

                  {/* Sitemap */}

                  <Spacer y={1} />

                  <Row>
                    <Image
                      width="100%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/SiteMap.png"
                    />
                  </Row>
                  <Spacer y={1} />

                  <Row justify="center">
                    <Text h5 color="$gray">
                      Sitemap
                    </Text>
                  </Row>
                  <Spacer y={2} />

                  {/* Paper wireframes */}

                  <Row>
                    <Text h4>Paper wireframe</Text>
                  </Row>
                  <Spacer y={1} />
                  <Row>
                    <Text
                      css={{
                        fontFamily: "$primary",
                        lineHeight: "$xl",
                      }}
                    >
                      Create the initial design by creating paper wireframes of various elements
                      from ideas, then highlighting the elements that appear frequently in the
                      dekstop wireframes, and also creating website variants. In addition, all
                      wireframes would be converted to digital wireframes for subsequent steps.
                    </Text>
                  </Row>

                  <Spacer y={1} />
                  <Row>
                    <Image
                      width="100%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/Homepage_paperwireframes.png"
                    />
                  </Row>

                  <Row justify="center">
                    <Text h5 color="gray">
                      Homepage
                    </Text>
                  </Row>

                  <Row>
                    <Image
                      width="100%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/Howto_tutorial_paperwireframe.png"
                    />
                  </Row>

                  <Row justify="center">
                    <Text h5 color="gray">
                      How-to tutorial
                    </Text>
                  </Row>

                  <Row>
                    <Image
                      width="100%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/Howto_tutorial_paperwireframe_desktop.png"
                    />
                  </Row>

                  <Row justify="center">
                    <Text h5 color="gray">
                      How-to tutorial (Website variant)
                    </Text>
                  </Row>

                  <Spacer y={2} />

                  <Row>
                    <Text h4>Digital wireframe</Text>
                  </Row>
                  <Spacer y={1} />

                  <Row>
                    <Image
                      width="70%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/App_digital_wireframe.png"
                    />
                  </Row>

                  <Row>
                    <Image
                      width="100%"
                      height="100%"
                      alt="Paper wireframes"
                      src="images/JustSnap/Website_digital_wireframe.png"
                    />
                  </Row>

                  <Spacer y={2} />

                  <Row>
                    <Text h4>Usability study</Text>
                  </Row>
                  <Spacer y={2} />

                  <Row>
                    <Image width="auto" src="images/JustSnap/App_lowfi_prototype.png" />
                  </Row>
                  <Spacer y={1} />

                  <Row>
                    <Image width="auto" src="images/JustSnap/Web_lowfi_prototype.png" />
                  </Row>
                  <Spacer y={1} />

                  <Row justify="center">
                    <Text h5 color="gray">
                      Low-fidelity prototype
                    </Text>
                  </Row>
                  <Spacer y={2} />

                  <Row>
                    <Text
                      css={{
                        fontFamily: "$primary",
                        lineHeight: "$xl",
                      }}
                    >
                      After creating a low-fi prototype, I conducted a usability study to evaluate
                      the initial solution and design. These are the parameters of this usability
                      study:
                    </Text>
                  </Row>

                  <Spacer y={1} />

                  <Grid.Container gap="2">
                    <Grid xs={12} sm={6}>
                      <TextCard content="Unmoderated usability study" headline="Study type" />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <TextCard headline="Location" content="Remote testing" />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <TextCard headline="Participants" content="5 participants" />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <TextCard headline="Length" content="10-15 minutes" />
                    </Grid>
                  </Grid.Container>

                  <Spacer y={1} />

                  <Row>
                    <Text h4>Main finding</Text>
                  </Row>
                  <Spacer y={1} />
                  <Row>
                    <Container
                      gap={0}
                      css={{
                        borderRadius: "$base",
                      }}
                    >
                      <Grid.Container gap={2}>
                        <Grid xs={12} sm={6}>
                          <EnumCard
                            number="1"
                            header="Navigation"
                            content="Users confused about the navigation on the how-to tutorial page.
                          They didn&#39;t know what to click when they want to go back because it has
                          2 similar back buttons. And some page didn't told user where they're at."
                          />
                        </Grid>
                        <Grid xs={12} sm={6}>
                          <EnumCard
                            number="2"
                            header="Using inconsistency word"
                            content="Users told that some components using different word but similar meaning 
                          such as 'log in' and 'sign in' and make users confused"
                          />
                        </Grid>
                      </Grid.Container>

                      {/* Low-fidelity Finding */}
                    </Container>
                  </Row>

                  <Spacer y={2} />
                </Container>
              </Grid>
            </Grid.Container>
          </Container>

          <Container sm>
            <Row justify="center">
              <Avatar
                color={""}
                icon={<MdDesignServices size="auto" color="white" />}
                size="xl"
                css={{ backgroundColor: "$red600" }}
              />
            </Row>
            <Spacer y={1} />

            <Row justify="center">
              <Text h5 color="$gray">
                REFINE THE DESIGN
              </Text>
            </Row>
            <Row justify="center">
              <Text h1 css={{ textAlign: "center" }}>
                Mockups & design system
              </Text>
            </Row>
            <Spacer y={2} />

            <Row justify="center">
              <Image width="100%" src="images/JustSnap/Design_system.jpg" />
            </Row>

            <Spacer y={2} />

            <Row>
              <Text>
                From the usability studies, I received a some feedbacks and insights on how to make
                the product more practical and address users' pain points. The first, is about the
                navigation on the application that feels confusing for users because It has similar
                purpose of 2 back buttons.
              </Text>
            </Row>
            <Spacer y={1} />

            <Row justify="center">
              <Image width="100%" src="images/JustSnap/UsabilityUpdate1.png" />
            </Row>
            <Spacer y={1} />

            <Row>
              <Text>
                Next, I also received feedbacks about the inconsistency word on the application.
                After that, I updated words that used on login process to be the same word.
              </Text>
            </Row>
          </Container>

          <Spacer y={1} />
          <Row justify="center">
            <Image height={1000} src="images/JustSnap/UsabilityUpdate2.png" />
          </Row>

          <Spacer y={2} />

          <Row justify="center">
            <Text h3>More mockups</Text>
          </Row>
          <Spacer y={2} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={8}>
              <Image height={400} src="images/JustSnap/Desktop_mockups/Desktop_gif.gif" />
            </Grid>
            <Grid xs={12} sm={4}>
              <Image height={400} src="images/JustSnap/Mobile_mockups/Mobile_gif.gif" />
            </Grid>
          </Grid.Container>
        </Container>

        <Spacer y={2} />

        <Container sm>
          <Row align="center">
            <Avatar
              color={""}
              icon={<MdAccessible size="auto" color="white" />}
              size="xl"
              css={{ backgroundColor: "$cyan600" }}
            />

            <Spacer x={1} />

            <Text h3>Accessibilities consideration</Text>
          </Row>

          <Spacer y={1} />

          <Row>
            <Grid.Container gap={3}>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="1"
                  header="Iconography"
                  content="Using iconography to help people easily understand what element is and what functional it can be action."
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="2"
                  header="Text hierarchy"
                  content="Design text hierarchy to make user easy to read."
                />
              </Grid>
            </Grid.Container>
          </Row>

          {/* Takeaways */}

          <Container sm>
            <Spacer y={2} />

            <Text h3>Takeaways</Text>
            <Spacer y={2} />

            <Text h4> Impact</Text>
            <Spacer y={1} />

            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              “This app has an interesting concept, I will use it if it's a real product.” one of
              the usability study said. The application concept is interesting and the user wait to
              see the final version and they will use it if it develop.
            </Text>
            <Spacer y={2} />

            <Text h4>What I learned</Text>

            <Spacer y={1} />

            <Grid.Container gap={2}>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="1"
                  header="Users impact"
                  content={
                    "This is the first project that I've feedback from users told me that they will using the product if it is a real product. From that feedback, It gave me a lot of encouragement. and I will keep doing this kind of project."
                  }
                />
              </Grid>
              <Grid xs={12} sm={6}>
                <EnumCard
                  number="2"
                  header="Sketch wireframes on real paper"
                  content={
                    "In previous projects, I only draw wireframes on my iPad that's I think it easy. But this project I've try for drawing on paper, I think it faster and sometime make me have more idea than drawing on iPad in some situations"
                  }
                />
              </Grid>
            </Grid.Container>

            <Spacer y={2} />
          </Container>
        </Container>
      </div>
      <Container fluid gap={2} css={{ backgroundColor: "$bedge", padding: "2rem 0" }}>
        <Container md>
          <Row>
            <Text h3>Other projects</Text>
          </Row>
          <Spacer y={2} />
          <Grid.Container gap={2}>
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/Delivery/Hero.png"
              header="Devliery tracking website"
              route="/delivery"
              subheader="Google UX Design"
              content="A responsive website with detailed package tracking from delivery and SMS notifications to keep users up to date on tracking activities."
              period="July 2022"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/Menual/Menual_Hero.png"
              header="Menual"
              route="/menual"
              subheader="Google UX Design"
              content="Food delivery application that can customize their orders as much as they want to, similar to ordering foods by themselves at the restaurant."
              period="July 2022"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/raisenAskNew.png"
              header="RaisenAsk"
              route="/raisenask"
              subheader="Computer Engineering Senior Project"
              Award="Best Popular Project"
              content="RaisenAsk is a platform for online interactive videoconferencing which is a tool commonly used in the COVID-19 pandemic."
              period="May 2022"
            />
          </Grid.Container>
          <Spacer y={2} />

          <Row justify="center">
            <HashLink to="/justsnap#top">
              <Button id="backToTop" auto light iconRight={<FaChevronUp />}>
                Back to top
              </Button>
            </HashLink>
          </Row>
          <Spacer y={2} />
        </Container>
      </Container>
    </>
  );
}
