import "./App.css";
import Home from "./pages/home.js";
import Navbar from "./components/Navbar.js";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Route, Routes, useLocation } from "react-router-dom";
import DailyTrip from "./pages/DailyTrip";
import AboutMe from "./pages/aboutMe";
import React from "react";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import Raisenask from "./pages/raisenask";
import Menual from "./pages/Menual";
import Delivery from "./pages/Delivery";
import JustSnap from "./pages/JustSnap";
import ScrolltoTop from "./components/ScrolltoTop";

function App() {
  const location = useLocation();

  const lightTheme = createTheme({
    type: "light",
    theme: {
      colors: {
        bedge: "rgb(238, 237, 222)",
        backgroundDense: "rgb(224, 221, 170)",
        primary: "rgb(32, 50, 57)",
        black: "rgb(20, 30, 39)",
        gray: "rgb(108, 117, 125)",
        white: "rgb(253, 253, 253)",
        orange: "#FF9946",
        yellow: "#FFF1B9",
      },
      space: { 1: "0.02", 2: "0.04" },
      fonts: { primary: "DM Sans", primaryThai: "Noto Sans Thai" },
      lineHeights: { sm: "1.2", md: "1.3", lg: "1.5" },
    },
  });

  const darkTheme = createTheme({
    type: "dark",
    theme: {
      colors: {
        bedge: "rgb(23, 23, 22)",
        primary: "rgb(32, 50, 57)",
        black: "rgb(23, 23, 22)",
        gray: "rgb(148, 155, 161)",
        white: "rgb(253, 253, 253)",
        primaryDark: "white",
        orange: "#472a13",
        yellow: "#4c4837",
      },
      space: { 1: "0.02", 2: "0.04" },
      fonts: { primary: "DM Sans", primaryThai: "Noto Sans Thai" },
      lineHeights: { sm: "1.2", md: "1.3", lg: "1.5" },
    },
  });

  return (
    <div className="App" style={{}}>
      <NextThemesProvider
        defaultTheme="light"
        attribute="class"
        value={{
          light: lightTheme.className,
          dark: darkTheme.className,
        }}
      >
        <NextUIProvider>
          <Navbar />
          <TransitionGroup>
            <CSSTransition timeout={300} classNames="fade" key={location.key}>
              <Routes location={location}>
                <Route index element={<Home />} />
                <Route path="aboutme" element={<AboutMe />} />

                <Route path="dailytrip" element={<DailyTrip />} />
                <Route path="raisenask" element={<Raisenask />} />
                <Route path="menual" element={<Menual />} />
                <Route path="delivery" element={<Delivery />} />
                <Route path="justsnap" element={<JustSnap />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </NextUIProvider>
      </NextThemesProvider>
    </div>
  );
}

export default App;
