import React from "react";
import {
  Text,
  Spacer,
  Grid,
  Col,
  Row,
  Tooltip,
  useTheme,
  Image,
  Button,
  Link,
} from "@nextui-org/react";
import { FaLinkedinIn, FaGithub, FaLine, FaPhoneAlt, FaMailBulk } from "react-icons/fa";

export default function Header() {
  const { theme } = useTheme();
  return (
    <>
      <Grid xs={12} sm={6} css={{ margin: "2rem 0" }}>
        <Image
          css={{ borderRadius: "0.5rem" }}
          width={450}
          height={450}
          src="/images/kamin2022_2.jpg"
          alt="profile"
          style={{
            borderRadius: "8rem",
          }}
        />
      </Grid>

      <Grid xs={12} sm={6}>
        <Col>
          <Row>
            <Text h2 id="FullName">
              Hi ! I'm Kamin Prakob 👋
            </Text>
          </Row>
          <Spacer y={0.5} />

          {/* Description */}

          <Row>
            <Text
              css={{
                lineHeight: theme.lineHeights.lg.value,
                letterSpacing: theme.letterSpacings.tight,
                fontFamily: "DM sans",
              }}
              size="1.125rem"
            >
              The goal of designs is to minimize user's pain points and become a toll to help with
              their lives.
            </Text>
          </Row>
          <Spacer y={1} />

          <Grid.Container gap={1}>
            <Grid xs={12} sm={6}>
              <FaPhoneAlt size={18} />
              <Spacer x={1} />
              <Text b css={{ fontFamily: "DM sans" }}>
                (+66) 83-315-1921
              </Text>
            </Grid>
            <Grid xs={12} sm={6}>
              <FaMailBulk size={18} />
              <Spacer x={1} />
              <Text b css={{ fontFamily: "DM sans" }}>
                kkamin.p@gmail.com
              </Text>
            </Grid>
          </Grid.Container>

          {/* Social Media */}
          <Spacer y={1} />
          <Row css={{ zIndex: "0" }}>
            <Link style={{ textDecoration: "none" }} href="https://www.linkedin.com/in/kaminp/">
              <Button
                color="$primary"
                auto
                icon={<FaLinkedinIn color="white" />}
                css={{ backgroundColor: "$primary" }}
              />
            </Link>
            <Spacer x={0.5} />

            <Link
              style={{ textDecoration: "none", color: "black" }}
              href="https://github.com/kaminpum"
            >
              <Button
                auto
                icon={<FaGithub color="white" />}
                color="$primary"
                css={{ backgroundColor: "$primary" }}
              />
            </Link>

            <Spacer x={0.5} />

            <Tooltip
              trigger="click"
              placement="bottom"
              content={
                <center>
                  <Text color="$black" b>
                    Add Me!
                  </Text>
                  <img src={"../images/LineID.jpg"} alt="Line ID" style={{ width: "10rem" }} />
                </center>
              }
            >
              <Button
                auto
                icon={<FaLine color="white" />}
                color="$primary"
                css={{ backgroundColor: "$primary" }}
              />
            </Tooltip>
          </Row>
        </Col>
      </Grid>
    </>
  );
}
