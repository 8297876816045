import "./css/home.css";
import "./css/box.css";
import React from "react";
import { Spacer, Grid, Container, Text, Row, Button } from "@nextui-org/react";
import Header from "../components/Header";
import { useTransform, useViewportScroll, motion } from "framer-motion";
import { FaChevronUp } from "react-icons/fa";
import ExperienceProjectHero from "../components/ExperienceProjectHero";
import InternshipContainer from "../components/InternshipContainer";
import { HashLink } from "react-router-hash-link";

function Home() {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.5]);

  return (
    <>
      {/* Header */}
      <Container sm gap={0} style={{ backgroundColor: "$bedge", maxWidth: "100vw" }}>
        <motion.div
          className="d-lg-block d-none"
          style={{ scale, backgroundColor: "$bedge", paddingBottom: "2rem" }}
        >
          <Container fluid css={{ backgroundColor: "$bedge" }}>
            <Grid.Container
              alignItems="center"
              css={{
                minHeight: "80vh",
                maxWidth: "70rem",
                zIndex: "0",
                scaleY: scrollYProgress,
                margin: "0 auto",
              }}
            >
              <Header />
            </Grid.Container>
          </Container>
        </motion.div>

        <div
          className="d-lg-none d-block"
          style={{
            scale,
            backgroundColor: "$bedge",
          }}
        >
          <Container sm css={{ backgroundColor: "$bedge", paddingBottom: "3rem" }}>
            <Grid.Container
              alignItems="center"
              css={{
                minHeight: "100vh",
                maxWidth: "80rem",
                padding: "0 1rem",
                zIndex: "0",
                scaleY: scrollYProgress,
                margin: "0 auto",
              }}
            >
              <Spacer y={5} />
              <Header />
            </Grid.Container>
          </Container>
        </div>
      </Container>
      <Spacer y={3} />

      {/* Experiences */}

      {/* Case Study project */}

      <Container md id="casestudy">
        <Row>
          <Text h2>Experiences</Text>
        </Row>
      </Container>
      <Spacer y={1} />
      <Container md>
        <Grid.Container gap={2}>
          <ExperienceProjectHero
            type="complete"
            imgSrc="/images/JustSnap/JustSnapThumbnail.png"
            header="JustSnap"
            route="/justsnap"
            subheader="Google UX Design"
            content=" Responsive website and application provided easy tutorial to follow and reliable how-to guide for anythings."
            period="July 2022"
          />
          <ExperienceProjectHero
            type="complete"
            imgSrc="/images/Delivery/Hero.png"
            header="Devliery tracking website"
            route="/delivery"
            subheader="Google UX Design"
            content="A responsive website with detailed package tracking from delivery and SMS notifications to keep users up to date on tracking activities."
            period="July 2022"
          />
          <ExperienceProjectHero
            type="complete"
            imgSrc="/images/Menual/Menual_Hero.png"
            header="Menual"
            route="/menual"
            subheader="Google UX Design"
            content="Food delivery application that can customize their orders as much as they want to, similar to ordering foods by themselves at the restaurant."
            period="July 2022"
          />
          <ExperienceProjectHero
            type="complete"
            imgSrc="/images/raisenAskNew.png"
            header="RaisenAsk"
            route="/raisenask"
            subheader="Computer Engineering Senior Project"
            Award="Best Popular Project"
            content="RaisenAsk is a platform for online interactive videoconferencing which is a tool commonly used in the COVID-19 pandemic."
            period="May 2022"
          />
          <ExperienceProjectHero
            type="complete"
            imgSrc="/images/DailyTrip.png"
            header="DailyTrip"
            route="/DailyTrip"
            subheader="Internship Assignment"
            content="DailyTrip is an application for trip planning and exploring new places based on given requirements during internship admission test."
            period="April 2021"
          />
        </Grid.Container>

        {/* 9Facesushi */}
      </Container>

      <Spacer y={2} />
      <Container md gap={2}>
        {/* Experiences */}

        <Row>
          <Text h2>Internship 🏃‍♂️</Text>
        </Row>

        <InternshipContainer imgSrc="/images/AgnosLogoWide.png" />
      </Container>

      <Spacer y={2} />

      <Row justify="center">
        <HashLink smooth to="/#top">
          <Button id="backToTop" auto light iconRight={<FaChevronUp />}>
            Back to top
          </Button>
        </HashLink>
      </Row>
      <Spacer y={2} />

      <Row justify="center">
        <Text color="$gray" css={{ textAlign: "center" }}>
          Developed by Kamin Prakob, since 2022.
        </Text>
      </Row>
      <Spacer y={0.5} />
      <Row justify="center">
        <Text color="$gray" css={{ textAlign: "center" }}>
          kkamin.p@gmail.com, Phone number: (+66) 83 315 1921
        </Text>
      </Row>
      <Spacer y={2} />
    </>
  );
}

export default Home;
