import { Avatar, Button, Col, Container, Grid, Image, Row, Spacer, Text } from "@nextui-org/react";
import React from "react";
import { FaChevronLeft, FaChevronUp, FaRegCommentDots } from "react-icons/fa";
import { MdDesignServices, MdScreenSearchDesktop } from "react-icons/md";
import TextCard from "../components/TextCard";
import EnumCard from "../components/EnumCard";
import { HashLink } from "react-router-hash-link";
import ExperienceProjectHero from "../components/ExperienceProjectHero";

export default function Raisenask() {
  return (
    <div className="">
      <Container sm css={{ paddingTop: "15vh" }}>
        <Row>
          <HashLink to="/#top">
            <Avatar
              pointer
              color="$black"
              icon={<FaChevronLeft color="white" />}
              squared
              css={{ backgroundColor: "$primary", zIndex: "0" }}
            />
          </HashLink>
        </Row>
        <Spacer y={1} />
        <Row>
          <Col>
            <Text h1>RaisenAsk</Text>
            <Text h4 color="$gray">
              a Web Application for Live Interactive Learning
            </Text>
          </Col>
        </Row>
        <Spacer y={2} />
        <Row>
          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/raisenAskNew.png"
            width="100%"
            height="100%"
          />
        </Row>
        <Spacer y={2} />

        <Grid.Container css={{ rowGap: "1rem" }}>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Duration:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: "$primary" }}>July 2021 - May 2022</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Project info:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: "$primary" }}>Computer engineering senior project.</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Tools:</Text>
                  <Spacer y={0.5} />

                  <Text css={{ fontFamily: "$primary" }}>
                    Figma, Google Form, Excel, Next.js, NextUI, JavaScript, HTML5 and CSS
                  </Text>
                </Col>
              </Row>
            </Container>
          </Grid>
        </Grid.Container>

        <Spacer y={2} />
        <Row>
          <Text h2>Overview</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            RaisenAsk is a web application for live interactive learning via video call streaming
            that includes a learning environment (a classroom management system) to support online
            learning. Since the COVID-19 epidemic, the education system has shifted from onsite to
            online learning and other tools are not designed for teaching and learning effectively.
          </Text>
        </Row>
        <Spacer y={1} />

        <Grid.Container gap={2}>
          <Grid xs={12} md={6}>
            <TextCard
              headline="Role"
              content="As a UX/UI designer and Front-end developer of the project."
            />
          </Grid>
          <Grid xs={12} md={6}>
            <TextCard
              headline="Responsibilities"
              content="Created a user research by using online survey, digital wireframing, also conducted usability study to enhance product experiences and develop a product by coding using ReactJS (JavaScript framework)."
            />
          </Grid>
        </Grid.Container>
        <Spacer y={2} />
        {/* Development Cycle */}
        <Container gap={0}>
          <Row>
            <Text h2>Development Cycle</Text>
          </Row>
          <Spacer y={2} />

          {/* User Research */}

          <Row align="center">
            <Avatar
              color={""}
              icon={<FaRegCommentDots size="auto" color="white" />}
              size="lg"
              css={{ backgroundColor: "$green700" }}
            />
            <Spacer x={1} />
            <Text h3>User research</Text>
          </Row>

          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            After that, the team decided to solve the problem of interaction in online learning and
            learning environments for teachers. Then, to spread and get as many participants as
            possible, we must do primary research with target users via Google Form.
          </Text>
          <Spacer y={1} />
          <Grid.Container gap={2}>
            <Grid xs={12} md={6}>
              <TextCard
                headline="Participant demographics"
                content="Students and teachers at the high school and college levels"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <TextCard
                headline="Goal"
                content="The team is curious about target user experiences and difficulties
            with current videoconferencing solutions."
              />
            </Grid>
            <Grid xs={12}>
              <TextCard
                headline="Outcome"
                content=" Following the distribution of the form, we received 191 replies from
            a wide range of students and teachers at various educational levels.
            The data was then cleaned to only include specified targets,
            yielding 162 student responses and 19 teacher responses within the
            target scope."
              />
            </Grid>
          </Grid.Container>

          <Spacer y={1} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/raisenAsk/researchForm.png"
            width="100%"
            height="100%"
          />

          <Spacer y={2} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            The form response includes questions that ask what experiences that user has had with
            videoconferencing and learning environment platforms. If you have problems, what are
            they? And user feedback will be divided into two categories below.
          </Text>
          <Spacer y={1} />
          <Text h4 css={{ textAlign: "center" }}>
            videoconferencing feature feedbacks
          </Text>
          <Spacer y={1} />
          <Grid.Container gap={2}>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard number="1" content="Some features are difficult to use." />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard number="2" content="Difficult to figure out how to use the application." />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard
                number="3"
                content="Cannot prepare in-class activities such as polls, quizzes, or
              exams before class begins."
              />
            </Grid>
          </Grid.Container>

          <Spacer y={1} />
          <Text h4 css={{ textAlign: "center" }}>
            Learning environment feature feedbacks
          </Text>
          <Spacer y={1} />

          <Grid.Container gap={2} justify="center">
            <Grid xs={12} md={6}>
              <EnumCard number="1" content="The class schedule is not obvious." />
            </Grid>
            <Grid xs={12} md={6}>
              <EnumCard
                number="2"
                content="To support of teaching, an outsourced program must be used."
              />
            </Grid>
            <Grid xs={12} md={6}>
              <EnumCard number="3" content="There is no attendance check feature." />
            </Grid>
            <Grid xs={12} md={6}>
              <EnumCard number="4" content="It is difficult to use." />
            </Grid>
          </Grid.Container>

          {/* Competitive Audit */}
          <Spacer y={2} />
          <Text h3>Competitive Audit</Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            My team and I conducted a competitive audit to identify the strengths and weaknesses of
            our competitor's products, which can help us design a solution that will not have such
            weaknesses and also be user-friendly.
          </Text>
          <Spacer y={2} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/raisenAsk/Competitive_audit.png"
            width="100%"
            height="100%"
          />

          <Spacer y={2} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            We discovered solutions suggested by users in the research form and performed a
            competitive audit to identify strengths and weaknesses in competitors' products. By
            studying the research data, we have discovered problem-solving ideas for our product.
          </Text>
          <Spacer y={1} />
          <Text h4>Solutions</Text>

          <Spacer y={1} />
          <Grid.Container gap={2}>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard
                number="1"
                content="Create support during videoconferencing teaching features."
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard
                number="2"
                content="Add ability to prepare activities in advance in leraning environment
              part of the web application."
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard
                number="3"
                content="Provide interactive tools to help students and teachers engagement during
              videoconferencing."
              />
            </Grid>
          </Grid.Container>

          {/* Wireframing */}

          <Spacer y={1} />

          <Row align="center">
            <Avatar
              color={""}
              icon={<MdDesignServices size="auto" color="white" />}
              size="lg"
              css={{ backgroundColor: "$red600" }}
            />
            <Spacer x={1} />
            <Text h3>Wireframing</Text>
          </Row>

          <Spacer y={1} />

          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            As a UX/UI designer on the team, I produce a wireframe to validate our solution
            assumptions, and the project must evolve to become an actual product. Because I had a
            limited amount of time in the design phase and spare time to develop the web
            application, I decided to build high fidelity instead to speed up the process.
          </Text>

          {/* User Interview */}

          <Spacer y={2} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            width={680}
            src="images/Teacher_view_wireframes.png"
          />

          <Spacer y={1} />

          <Row justify="center">
            <Text h5 color="$gray" css={{ textAlign: "center" }}>
              Videoconferencing view
            </Text>
          </Row>

          <Spacer y={2} />

          <Image css={{ borderRadius: "0.5rem" }} width={680} src="images/ClassroomStudent.png" />

          <Spacer y={1} />

          <Row justify="center">
            <Text h5 color="$gray" css={{ textAlign: "center" }}>
              Classroom page
            </Text>
          </Row>

          <Spacer y={2} />

          <Image css={{ borderRadius: "0.5rem" }} width={680} src="images/ArchiveOwnerView.png" />

          <Spacer y={1} />

          <Row justify="center">
            <Text h5 color="$gray" css={{ textAlign: "center" }}>
              Archive page
            </Text>
          </Row>

          <Spacer y={2} />
          <Row justify="center"></Row>
          <Spacer y={2} />
          {/* Insert new wireframe */}
          <Text h3>Development</Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            As a front-end developer, my team and I use the Next.js JavaScript framework and NextUI
            as core tools for web-applications, as well as peerJS (based on WebRTC technology) and
            socket.io to support video conferencing features. RaisenAsk developed from the second
            wireframe design and features were finalized. The web-application will be completed as a
            demo version with most features functional by the end of April 2022, ready to be tested
            by target users later.
          </Text>
          <Spacer y={1} />
          <Text h5>Application structures</Text>
          <Spacer y={1} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/raisenAsk/applicationStructures.png"
            width="100%"
            height="100%"
          />

          <Spacer y={2} />
          {/* Module list */}

          <Row align="center">
            <Avatar
              color={""}
              icon={<MdScreenSearchDesktop size="auto" color="white" />}
              size="lg"
              css={{ backgroundColor: "$purple600" }}
            />
            <Spacer x={1} />
            <Text h3>Usability study</Text>
          </Row>
          <Spacer y={1} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              Following the development process, my team and I created a RaisenAsk demo version to
              conduct usability study to see how the application provides solutions to target users.
            </Text>
          </Row>
          <Spacer y={1} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/raisenAsk/appFlow.png"
            width="100%"
            height="100%"
          />

          <Spacer y={2} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              First, we contact the same users as in the first phase to compare the initial feedback
              and the final product. During unmoderated usability study, one of us will operate as a
              moderator, giving users a test task while the other observes how users react to
              products that are spoken for or not. So, after that, we send a Google Form evaluation
              to users in order to collect quantitative feedback and summarize qualitative feedback
              from user responses during the interview.
            </Text>
          </Row>
          {/* Insert usability study */}
          <Spacer y={2} />
          <Container>
            <Row align="center">
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  alt=""
                  src="/images/raisenAsk/studentForm.png"
                  width="100%"
                  height="100%"
                />
              </Col>
              <Spacer x={1} />
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  alt=""
                  src="/images/raisenAsk/teacherForm.png"
                  width="100%"
                  height="100%"
                />
              </Col>
            </Row>
          </Container>
          <Spacer y={1} />

          <Row>
            <Image css={{ borderRadius: "0.5rem" }} width={400} src="images/RaisenAsk_Gif.gif" />
          </Row>

          <Spacer y={2} />
          <Text h4>Usability study parameters</Text>
          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <TextCard headline="Study Type" content="Unmoderated usability study" />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextCard headline="Location" content="Remote testing" />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextCard
                headline="Participants"
                content={"10 college level students and 4 high school teachers"}
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <TextCard headline="Length" content="1.5 - 2 hours" />
            </Grid>
          </Grid.Container>

          <Spacer y={1} />

          <Row justify="center">
            <Text h5 color="$gray">
              MAIN FINDINGS
            </Text>
          </Row>

          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <EnumCard
                number="1"
                header="UX writing"
                content="Some button, menu using misunderstand about what they can do and what will happend after press call to action buttons."
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EnumCard
                number="2"
                header="Icons misinterpretation"
                content="Teacher misunderstanding icons using on videoconferencing tools."
              />
            </Grid>
            <Grid xs={12}>
              <EnumCard
                number="3"
                header="System feedback"
                content='In videoconferencing tools, there is feature called "Poke" to shake other participants screen, But when teacher press the button, there is no feedback to teacher that student already poked.'
              />
            </Grid>
          </Grid.Container>

          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            All of our participants were tested with specific tasks in each role in each section of
            the application (Authentication module, Classroom module, Video Call module, Interactive
            module, and Archive module), and testing sessions were recorded to be viewed later.
          </Text>
          {/* Insert Image in each module */}
          <Spacer y={2} />
          <Text h3>Challenging</Text>
          <Spacer y={1} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              The usability study should take place in the second week of May 2022, with 50 students
              and 10 teachers taking part to provide diverse feedback and reliable results. However,
              at the beginning and end of the college semesters, target users are unavailable to
              test our product and are not in a real teaching situation, making the results
              unreliable from an interaction aspect.
            </Text>
          </Row>
          <Spacer y={1} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              Furthermore, the project focuses on the learning environment for teachers as well as
              the interaction between students and teachers. Because of the usability issue,
              evaluating the interactions is difficult.
            </Text>
          </Row>
          <Spacer y={2} />
          <Text h3>What I learned</Text>
          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12} md={6}>
              <EnumCard
                number="1"
                header="Limit of development time"
                content="Because this is a senior year computer engineering student's final project, our group has limited time for design and development. As a result, some of the design process is not used in the project to increase development time."
              />
            </Grid>

            <Grid xs={12} md={6}>
              <EnumCard
                number="2"
                header="Recruiting participants for usability studies"
                content="Because there aren't many people available to be recruited for testing during the usability study period. As a result, the number of participants is not on our action plan."
              />
            </Grid>
          </Grid.Container>
        </Container>

        <Spacer y={2} />
      </Container>
      <Container fluid gap={2} css={{ backgroundColor: "$bedge", padding: "2rem 0" }}>
        <Container md>
          <Row>
            <Text h3>Other projects</Text>
          </Row>
          <Spacer y={2} />
          <Grid.Container gap={2}>
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/DailyTrip.png"
              header="DailyTrip"
              route="/DailyTrip"
              subheader="Internship Assignment"
              content="DailyTrip is an application for trip planning and exploring new places based on given requirements during internship admission test."
              period="April 2021"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/JustSnap/JustSnapThumbnail.png"
              header="JustSnap"
              route="/justsnap"
              subheader="Google UX Design"
              content=" Responsive website and application provided easy tutorial to follow and reliable how-to guide for anythings."
              period="July 2022"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/Delivery/Hero.png"
              header="Devliery tracking website"
              route="/delivery"
              subheader="Google UX Design"
              content="A responsive website with detailed package tracking from delivery and SMS notifications to keep users up to date on tracking activities."
              period="July 2022"
            />
          </Grid.Container>
          <Spacer y={2} />

          <Row justify="center">
            <HashLink to="/raisenask#top">
              <Button id="backToTop" auto light iconRight={<FaChevronUp />}>
                Back to top
              </Button>
            </HashLink>
          </Row>
          <Spacer y={2} />
        </Container>
      </Container>
    </div>
  );
}
