import { Card, Container, Row, Spacer, Text } from "@nextui-org/react";
import React from "react";

export default function TextCard(props) {
  const { headline, content } = props;
  return (
    <Card variant="bordered" css={{ padding: "1rem" }}>
      <Container gap={0}>
        <Row justify="center">
          <Text h4>{headline}</Text>
        </Row>
        <Spacer y={1} />

        <Text
          css={{
            fontFamily: "$primary",
            lineHeight: "$xl",
            textAlign: "center",
            margin: "auto",
          }}
        >
          {content}
        </Text>
      </Container>
    </Card>
  );
}
