import React from "react";
import { Container, Grid, Image, Row, Spacer, Text, useTheme } from "@nextui-org/react";

export default function InternshipContainer(props) {
  const { imgSrc, imgAlt } = props;
  const { theme } = useTheme();

  return (
    <>
      <div className="d-xl-block d-none">
        <Spacer y={3} />

        <Grid.Container
          alignItems="center"
          css={{
            zIndex: "1",
            background: `url(${imgSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            margin: "0 auto",
            maxWidth: "70vw",
            height: "auto",
            padding: "5rem 0rem",
            borderRadius: "3rem",
          }}
        >
          {/* <RelatedProject /> */}

          <Grid xs={12} md={6}></Grid>
          <Grid xs={12} md={6}>
            <Container gap={0} justify="center" alignItems="center" css={{ padding: "0 2rem" }}>
              <Text h2 color="$white">
                Agnos Health Co. LTD
              </Text>
              <Spacer y={0.5} />
              <Text h4 color="$white" css={{ letterSpacing: theme.letterSpacings.tight }}>
                UX Designer intern
              </Text>
              <Spacer y={0.5} />

              <Text h5 color="$white" css={{ letterSpacing: theme.letterSpacings.tight }}>
                June 2021 - August 2021
              </Text>

              <Spacer y={1} />

              <Text
                color="$white"
                css={{
                  fontFamily: "DM Sans",
                  letterSpacing: theme.letterSpacings.tight,
                }}
              >
                Collaborated with the design team to create and deliver the UX and UI for the
                company's new platform with real-world experiences. Figma is a tool that we used
                primarily in all processes.
              </Text>
              <Spacer y={1} />

              <Text
                h4
                color="white"
                weight={"bold"}
                css={{ letterSpacing: theme.letterSpacings.tight }}
              >
                Responsibility
              </Text>
              <Spacer y={0.5} />
              <Container gap={0} css={{}}>
                <Spacer y={0.5} />
                <div
                  style={{
                    fontFamily: "DM Sans",
                    letterSpacing: theme.letterSpacings.tight,
                    color: "white",
                  }}
                >
                  <li>
                    Researched competitors' applications and user flows before applying to the
                    platform.
                  </li>
                  <li>Take notes and summarize user insights during user interviews.</li>
                  <li>
                    Create a design system and high-fidelity wireframes, design UI based on user's
                    feedback and develop a MVP.
                  </li>
                  <li>Design a prototype and plan usability study.</li>
                </div>
              </Container>
            </Container>
          </Grid>
        </Grid.Container>
        <Spacer y={3} />
      </div>

      <div className="d-xl-none d-block">
        <Grid.Container
          md
          alignItems="center"
          css={{
            zIndex: "1",
            margin: "0 auto",
            maxWidth: "100vw",
            height: "auto",
            padding: "3rem 0rem",
            borderRadius: "3rem",
          }}
        >
          <Grid xs={12} md={6}>
            <Container md gap={0} justify="center" alignItems="center" css={{ padding: "0 2rem" }}>
              <Row>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src={imgSrc}
                  alt={imgAlt}
                  width="100%"
                  height="auto"
                  css={{ borderRadius: "3rem" }}
                />
              </Row>
              <Spacer y={1} />
              <Text h2>Agnos Health Co. LTD</Text>
              <Spacer y={0.5} />
              <Text h4 css={{ letterSpacing: theme.letterSpacings.tight }}>
                UX Designer intern
              </Text>
              <Spacer y={0.5} />

              <Text color="$gray" h5 css={{ letterSpacing: theme.letterSpacings.tight }}>
                June 2021 - August 2021
              </Text>

              <Spacer y={1} />

              <Text
                css={{
                  fontFamily: "DM Sans",
                  letterSpacing: theme.letterSpacings.tight,
                }}
              >
                Collaborated with the design team to create and deliver the UX and UI for the
                company's new platform with real-world experiences. Figma is a tool that we used
                primarily in all processes.
              </Text>
              <Spacer y={1} />

              <Text h4 weight={"bold"} css={{ letterSpacing: theme.letterSpacings.tight }}>
                Responsibility
              </Text>
              <Spacer y={0.5} />
              <Container gap={0} css={{}}>
                <Spacer y={0.5} />
                <div
                  style={{
                    fontFamily: "DM Sans",
                    letterSpacing: theme.letterSpacings.tight,
                  }}
                >
                  <li>
                    Researched competitors' applications and user flows before applying to the
                    platform.
                  </li>
                  <li>Take notes and summarize user insights during user interviews.</li>
                  <li>
                    Create a design system and high-fidelity wireframes, design UI based on user's
                    feedback and develop a MVP.
                  </li>
                  <li>Design a prototype and plan usability study.</li>
                </div>
              </Container>
            </Container>
          </Grid>
        </Grid.Container>
      </div>
    </>
  );
}
