import {
  Button,
  Card,
  Col,
  Container,
  Grid,
  Image,
  Link,
  Row,
  Spacer,
  Text,
} from "@nextui-org/react";
import "./css/aboutme.css";
import React from "react";
import { FaPaperclip } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { FaChevronRight } from "react-icons/fa";

export default function AboutMe() {
  // const CertificateList = [
  //   {
  //     id: 1,
  //     title: "Foundations of User Experience (UX) Design",
  //     image: "images/cert1.JPEG",
  //     link: "https://coursera.org/share/42bc00d6af66b96f1f6b2aedd3cfddc3",
  //   },
  //   {
  //     id: 2,
  //     title: "Start the UX Design Process: Empathize, Define, and Ideate",
  //     image: "images/cert2.JPEG",
  //     link: "https://coursera.org/share/f2c6d4b758f46ef15430795b0f8f5dc0",
  //   },
  //   {
  //     id: 3,
  //     title: "Build Wireframes and Low-Fidelity Prototypes",
  //     image: "images/cert3.JPEG",
  //     link: "https://coursera.org/share/0e603d86f7832b8bdd65b5792cbb2c8f",
  //   },
  //   {
  //     id: 4,
  //     title: "Conduct UX Research and Test Early Concepts",
  //     image: "images/cert4.JPEG",
  //     link: "https://coursera.org/share/7ae76bcc9bcd7843936fbca5ceb4ec35",
  //   },
  //   {
  //     id: 5,
  //     title: "Create High-Fidelity Designs and Prototypes in Figma",
  //     image: "images/cert5.JPEG",
  //     link: "https://coursera.org/share/b6302a2eb321b64fc613adf3ac7c90e7",
  //   },

  //   {
  //     id: 6,
  //     title: "Responsive Web Design in Adobe XD",
  //     image: "images/cert6.JPEG",
  //     link: "https://coursera.org/share/07404854bfb4ee5159bb3f5368e2cf7d",
  //   },
  //   {
  //     id: 7,
  //     title: "Design a User Experience for Social Good & Prepare for Jobs",
  //     image: "images/cert7.JPEG",
  //     link: "https://coursera.org/share/1b78747b24eda2934e8ae3ff0a6553cb",
  //   },
  //   {
  //     id: 8,
  //     title: "Google UX Design",
  //     image: "images/cert8.JPEG",
  //     link: "https://coursera.org/share/f2d9abbb49c2d81f0acb3a8a401c737e",
  //   },
  // ];

  return (
    <Container gap={0} fluid css={{ paddingTop: "20vh", backgroundColor: "$bedge" }}>
      <Container sm>
        <Grid.Container css={{ rowGap: "1rem" }}>
          {/* Introdcution */}

          <Grid xs={12} sm={5}>
            <Row align="center">
              <Col>
                <Text h1 color="$gray" id="FullName">
                  My name is
                </Text>
                <Text h1 id="FullName">
                  Kamin Prakob😃
                </Text>

                <Spacer y={2} />
                <Text size="1.125rem">
                  The goal of designs is to minimize user's pain points and become a toll to help
                  with their daily lives.
                </Text>
                <Spacer y={2} />

                <Row justify="flex-end" css={{ zIndex: "0" }}>
                  <Link href="https://drive.google.com/file/d/12fSFt-aCxgd5updKOyGjbvm5bQ2cwsbW/view?usp=sharing">
                    <Button
                      auto
                      icon={<FaPaperclip />}
                      css={{
                        fontSize: "1rem",
                      }}
                    >
                      Resume
                    </Button>
                  </Link>
                </Row>
              </Col>
            </Row>
          </Grid>
          <Grid xs={12} sm={1}></Grid>

          {/* Image Group */}

          <Grid.Container xs={12} sm={6}>
            <Row align="center" css={{ gap: "1rem" }}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  showSkeleton
                  src="images/KaminPrakob.jpg"
                  height="100%"
                  width="100%"
                />
              </Col>
              <Col>
                <Image css={{ borderRadius: "0.5rem" }} showSkeleton src="images/kamin2022_2.jpg" />
                <Spacer y={1} />
                <Image css={{ borderRadius: "0.5rem" }} showSkeleton src="images/PiezoPlaza.jpeg" />
              </Col>
            </Row>
          </Grid.Container>
        </Grid.Container>
      </Container>
      <Spacer y={4} />
      <Container md>
        <Row>
          <Col>
            <Text h2>Certificate</Text>
          </Col>
        </Row>
        <Spacer y={2} />

        {/* Certificate */}

        {/* {CertificateList.map((item) => {
            return (
              <Grid xs={12} sm={6} md={4}>
                <Card
                  shadow
                  css={{
                    borderRadius: "1rem",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Image css={{borderRadius:'1rem'}} src={item.image} alt={item.title} />
                  <Spacer y={1} />
                  <Text h5>{item.title}</Text>
                  <Spacer y={1} />
                  <Button auto size="sm" onClick={() => window.open(item.link, "_blank")}>
                    View Certificate
                  </Button>
                </Card>
              </Grid>
            );
          })} */}

        <Grid.Container gap={2} justify="center" alignItems="center" css={{ height: "auto" }}>
          <Grid xs={12} sm={6}>
            <Image
              css={{ borderRadius: "0.5rem" }}
              objectFit="cover"
              src="images/cert8.jpeg"
              alt="Google UX Design certificate"
            />
          </Grid>

          <Grid xs={12} sm={6} justify="flex-end">
            <Container>
              <Col>
                <Text h5 color="$gray">
                  Coursera
                </Text>
                <Text h2>Google UX Design</Text>

                <Spacer y={1} />
                <Text
                  size={18}
                  css={{
                    fontFamily: "$primary",
                  }}
                >
                  Learning UX design foundations and how to complete the design process from
                  beginning to end. Empathizing with users; Defining user pain points; Coming up
                  with ideas for design solutions; Creating wireframes, mockups, and prototypes;
                  Testing designs through usability studies; Iterating on designs based on feedback.
                </Text>
                <Spacer y={2} />
                <Row justify="center" align="center">
                  <Grid.Container justify="center" alignItems="center" gap={2}>
                    <Grid xs={12} sm={6} justify="center">
                      <Text color="$gray">32 weeks (7 courses included)</Text>
                    </Grid>
                    <Grid xs={12} sm={6} justify="center">
                      <Link href="https://coursera.org/share/f2d9abbb49c2d81f0acb3a8a401c737e">
                        <Button size="lg">
                          View certificate
                          <Spacer x={0.5} />
                          <FaChevronRight />
                        </Button>
                      </Link>
                    </Grid>
                  </Grid.Container>
                </Row>
              </Col>
            </Container>
          </Grid>
        </Grid.Container>
      </Container>
      <Spacer y={4} />

      {/* Extracurricular Activities */}

      <Container md>
        <Text h2 css={{ textAlign: "center" }}>
          Extracurricular Activities
        </Text>
        <Spacer y={2} />

        {/* Comcamp */}

        <Container css={{ borderRadius: "$base" }}>
          <Card variant="bordered" css={{ padding: "2rem" }}>
            <Row justify="center">
              <Text h3>KMUTT Comcamp 31st</Text>
            </Row>
            <Spacer y={1} />

            <Row justify="center">
              <Text align="center">
                Educational camp for high school students that are interested in becoming
                knowledgeable computer engineers by thinking, designing, and applying knowledge into
                practice in the areas of software, hardware, and networking of the computer system.
              </Text>
            </Row>
            <Spacer y={1} />
            <Grid.Container gap={0} justify="center" css={{ rowGap: "1rem" }}>
              <Grid xs={12} sm={3}>
                <Row justify="center" align="center">
                  <Text h5>Period: &nbsp;</Text>
                  <Text align="center"> 15 - 19 April 2019</Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={6}>
                <Row justify="center" align="center">
                  <Text h5>Location: &nbsp;</Text>
                  <Text align="center">
                    {" "}
                    King Mongkuy's University of Technology Thonburi (KMUTT)
                  </Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={3}>
                <Row justify="center" align="center">
                  <Text h5>Role: &nbsp;</Text>
                  <Text align="center"> Head of wellfare section.</Text>
                </Row>
              </Grid>
            </Grid.Container>
          </Card>

          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={4}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                showSkeleton
                src="images/comcamp/DSC_0265.jpg"
                height="100%"
                width="100%"
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                showSkeleton
                src="images/comcamp/DSC_0005.jpg"
                height="100%"
                width="100%"
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                showSkeleton
                src="images/comcamp/DSC_0150.jpg"
                height="100%"
                width="100%"
              />
            </Grid>
          </Grid.Container>
        </Container>

        <Spacer y={2} />

        {/* CPE Rsa8 */}

        <Container css={{ borderRadius: "$base" }}>
          <Card variant="bordered" css={{ padding: "2rem" }}>
            <Row justify="center">
              <Text h3>KMUTT CPE RSA 8th</Text>
            </Row>
            <Spacer y={1} />

            <Row justify="center">
              <Text align="center">
                Volunteer to help the schools that lack technological tools, knowledge, and
                necessities, and include repairing education building.
              </Text>
            </Row>
            <Spacer y={1} />
            <Grid.Container gap={0} justify="center" css={{ rowGap: "1rem" }}>
              <Grid xs={12} sm={3}>
                <Row justify="center" align="center">
                  <Text h5>Period: &nbsp;</Text>
                  <Text align="center"> 15 - 19 April 2019</Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={6}>
                <Row justify="center" align="center">
                  <Text h5>Location: &nbsp;</Text>
                  <Text align="center">Ban Phai Kok Wua School, Nakornpathom</Text>
                </Row>
              </Grid>
              <Grid xs={12} sm={3}>
                <Row justify="center" align="center">
                  <Text h5>Role: &nbsp;</Text>
                  <Text align="center">Volunteer</Text>
                </Row>
              </Grid>
            </Grid.Container>
          </Card>

          <Spacer y={1} />

          <Grid.Container gap={2}>
            <Grid xs={12}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                src="images/rsa/Ending.jpg"
                height="100%"
                width="100%"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                src="images/rsa/IMG_2322.JPG"
                height="100%"
                width="100%"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <Image
                css={{ borderRadius: "0.5rem" }}
                src="images/rsa/painting_wall.jpg"
                height="100%"
                width="100%"
              />
            </Grid>
          </Grid.Container>
        </Container>
      </Container>
      <Spacer y={2} />
      <Row justify="center">
        <HashLink to="/aboutme#top" smooth>
          <Button id="backToTop" auto light iconRight={<FaChevronUp />}>
            Back to top
          </Button>
        </HashLink>
      </Row>
      <Spacer y={2} />
    </Container>
  );
}
