import { Avatar, Button, Col, Container, Grid, Image, Row, Spacer, Text } from "@nextui-org/react";
import React from "react";
import { FaChevronLeft, FaChevronUp } from "react-icons/fa";
import EnumCard from "../components/EnumCard";
import TextCard from "../components/TextCard";
import { HashLink } from "react-router-hash-link";
import ExperienceProjectHero from "../components/ExperienceProjectHero";

export default function DailyTrip() {
  return (
    <div className="">
      <Container sm css={{ paddingTop: "15vh" }}>
        <Row>
          <HashLink to="/#top">
            <Avatar
              pointer
              color="$black"
              icon={<FaChevronLeft color="white" />}
              squared
              css={{ backgroundColor: "$primary", zIndex: "0" }}
            />
          </HashLink>
        </Row>
        <Spacer y={1} />
        <Row>
          <Text h1>DailyTrip</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Image
            css={{ borderRadius: "0.5rem" }}
            alt=""
            src="/images/DailyTrip.png"
            width="100%"
            height="100%"
          />
        </Row>
        <Spacer y={2} />

        <Grid.Container css={{ rowGap: "1rem" }}>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Duration:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: "$primary" }}>9th April - 13rd April 2021</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Project info:</Text>
                  <Spacer y={0.5} />
                  <Text css={{ fontFamily: "$primary" }}>Wongnai Internship assignment</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
          <Grid xs={12} sm={4}>
            <Container gap={0}>
              <Row>
                <Col>
                  <Text h4>Tools:</Text>
                  <Spacer y={0.5} />

                  <Text css={{ fontFamily: "$primary" }}>Adobe XD and Google Form</Text>
                </Col>
              </Row>
            </Container>
          </Grid>
        </Grid.Container>

        <Spacer y={2} />
        <Row>
          <Text h2>Overview</Text>
        </Row>
        <Spacer y={2} />
        <Row>
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            When people have the time and money to unwind from their daily lives, the most important
            thing they enjoy doing is traveling. They will always make a travel plan before leaving.
            However, the plan does not always go as planned. If you have no experience in a
            non-hometown area, it is difficult to decide where to go because you must already know
            the famous places but not the local ones.
          </Text>
        </Row>
        <Spacer y={2} />
        {/* Solutions */}

        <Container
          gap={2}
          css={{
            backgroundColor: "$yellow",
            borderRadius: "3rem",
            paddingTop: "2rem",
          }}
        >
          <Row>
            <Text h3>Solutions</Text>
          </Row>
          <Spacer y={1} />
          <Row>
            <Text
              css={{
                fontFamily: "$primary",
                lineHeight: "$xl",
              }}
            >
              I want to make it possible for people to travel to new places and have more "focus" on
              content than ever before. There will be benefits for users of the new sight, but there
              will also be benefits for shops, cafés, and other attractions that will become known
              and promote the local tourism economy.
            </Text>
          </Row>
          <Spacer y={2} />
          <Image css={{ borderRadius: "0.5rem" }} src="/images/Diary.png" alt="" width={450} />
          <Spacer y={2} />

          <Row justify="center">
            <Col>
              <Text h3 css={{ textAlign: "center" }}>
                Diary
              </Text>
              <Text css={{ textAlign: "center" }}>
                The feature that makes local people or tourists can record a short video in any
                situation to share experiences with others who follow. Also, people can interact
                with by love or reply.
              </Text>
            </Col>
          </Row>
          <Spacer y={2} />

          <Image css={{ borderRadius: "0.5rem" }} src="/images/Journal.png" alt="" width={450} />

          <Spacer y={2} />

          <Row justify="center">
            <Col>
              <Text h3 css={{ textAlign: "center" }}>
                Journal
              </Text>
              <Text css={{ textAlign: "center" }}>
                Traveling blog for traveler, new way to share experiences with location tagging and
                travel route to make others people feel the same experiences before they go by they
                own in any chance
              </Text>
            </Col>
          </Row>
          <Spacer y={2} />
        </Container>
        <Spacer y={2} />

        {/* Design Process */}

        <Container>
          <Text h2>Design Process</Text>
          <Spacer y={2} />
          <Text h4>Lean UX framework</Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            The Lean UX process prioritizes reducing waste of time and resources while producing a
            usable product as soon as possible. The process is iterative, which means that the team
            keeps updating and revising the product as they gather user research and stakeholder
            feedback.
          </Text>
          <Spacer y={2} />
          <Image
            css={{ borderRadius: "0.5rem" }}
            src="/images/LeanUX.png"
            alt=""
            width="70%"
            height="70%"
          />
        </Container>
        <Spacer y={2} />

        {/* Think */}

        <Container>
          <Text h2 color="#3AA757">
            Think
          </Text>
          <Spacer y={2} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            Because of the problems I already know about, I need to look for more information to
            understand what users are really going through and feeling. So I designed interview
            forms for distribution in order to gather more information about the problem.
          </Text>
          <Spacer y={2} />
          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <TextCard
                headline="Participant Demographics"
                content="6 participants, as university student living in Bangkok"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextCard
                headline="Goal"
                content={
                  "I" +
                  "'" +
                  "d" +
                  " " +
                  "like to learn more about how they think and act when making travel plans."
                }
              />
            </Grid>
            <Grid xs={12}>
              <TextCard
                headline="Outcome"
                content="From my interview forms, I discoverd that the answers from
            participants are likely common in solution-solving methods and the
            basic things or features that help them."
              />
            </Grid>
          </Grid.Container>

          <Spacer y={2} />

          <Text h3>Main Finding</Text>
          <Spacer y={1} />
          <Grid.Container gap="2">
            <Grid xs={12} md={6} lg={4}>
              <EnumCard number="1" content="Searching for places online" />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard
                number="2"
                content="It is hard to decide where to
            go by themselves."
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <EnumCard number="3" content="Reviews are the most impact things." />
            </Grid>
          </Grid.Container>

          <Spacer y={2} />
          <Text h3>Competitors Analysis</Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            Competitor apps that have been researched have a feature that allows users to search for
            attractions, hotels, and tickets in nearby areas. It also implies a good deal or a
            seasonal attraction.
          </Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            In general, apps tend not to focus on friendly reviews. So I thought if we had one user
            to create stories and experiences honestly. It should have a better experience for other
            users.
          </Text>

          <Spacer y={2} />
          <Grid.Container>
            <Grid xs={12} sm={6} md={4}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Attractions.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Text h3 css={{ textAlign: "center" }}>
                  Attractions
                </Text>
              </Col>
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Discovery.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Discovery
                </Text>
              </Col>
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/NearbyPlaces.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Nearby Places
                </Text>
              </Col>
            </Grid>
          </Grid.Container>
          <Row></Row>
        </Container>
        <Spacer y={2} />

        {/* Make */}
        <Container>
          <Text h2 color="#FABB2D">
            Make
          </Text>
          <Spacer y={2} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            Following the research findings, I began to design the new application by wireframing
            and prototyping to visualize the solution and allow users to interact with it. I will
            collect user feedback for iterative improvement.
          </Text>
          <Spacer y={2} />
          <Text h3>Digital wireframes</Text>
          <Spacer y={2} />
          <Grid.Container>
            <Grid xs={12} sm={6}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Home_mockup.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Home
                </Text>
              </Col>
            </Grid>
            <Grid xs={12} sm={6}>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Journal_mockup.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Journal
                </Text>
              </Col>
            </Grid>
          </Grid.Container>

          <Spacer y={2} />
          <Grid.Container justify="center">
            <Grid>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Detail_mockup.png"
                  alt=""
                  width={300}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Detail Page
                </Text>
              </Col>
            </Grid>
          </Grid.Container>
        </Container>

        <Spacer y={2} />

        {/* Check */}

        <Container>
          <Text h2 color="#4688F1">
            Check
          </Text>
          <Spacer y={2} />
          <Text h3>Evaluate</Text>

          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            Following the design process, I create the first prototype to determine whether users
            can interact with and respond appropriately to the given task in order to evaluate the
            product and ensure that the product solves the problem as effectively as possible.
          </Text>
          <Spacer y={2} />
          <Text h3>Usability study</Text>
          <Spacer y={1} />

          <Image
            css={{ borderRadius: "0.5rem" }}
            width={450}
            src="images/DailyTrip_Hi_fi_prototype.png"
          />

          <Spacer y={2} />

          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            A usability study entails having real users interact with a product and evaluating how
            simple the application is. To evaluate the product, I must test the prototype with real
            users by assigning tasks to them. If users encounter a problem, I will collect data,
            analyze it, and improve the prototype in the next interaction before releasing the
            product.
          </Text>
          <Spacer y={2} />

          <Grid.Container gap={2}>
            <Grid xs={12} sm={6}>
              <EnumCard
                number="1"
                content="Swipe up to find the hotel card, tap on it, and
                  write a review of the hotel."
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <EnumCard
                number="2"
                content="Tap the journal navigation menu, tap the first journal, swipe
                through to the bottom, and comment."
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <EnumCard
                number="3"
                content="Tap the first diary on the home page, tap the like button, and
                go back to the main screen."
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <EnumCard
                number="4"
                content="Tap the about me navigation menu, go to bookmark and
                un-bookmark the second blog, then go back to the home page."
              />
            </Grid>
          </Grid.Container>
          <Spacer y={2} />
          <Text h3>Mockup</Text>
          <Spacer y={1} />
          <Text
            css={{
              fontFamily: "$primary",
              lineHeight: "$xl",
            }}
          >
            After conducting usability study, I discovered that users encounter challenges when
            completing the assigned task. The most difficult issue we encountered was determining
            how to write a review on the location detail page. So, I performed a visual design
            iteration to fix and improve the user interface to make it more friendly.
          </Text>
          <Spacer y={2} />

          {/* UI Enhance */}

          <Container gap={2}>
            <Row>
              <Col>
                <Text h5>Increase visibility:</Text>
                <Spacer y={1} />
                <Text
                  css={{
                    fontFamily: "$primary",
                    lineHeight: "$xl",
                  }}
                >
                  In the first design, I chose the look of a "write review" button as a floating
                  plus icon button. However, after the usability study, they had no idea what the
                  floating button was doing. So, I changed the way users write reviews by adding a
                  "write review" button to the reviews section, giving users more clarity.
                </Text>
              </Col>
            </Row>
            <Spacer y={1} />
            <Row justify="center">
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Atrraction_before.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Before
                </Text>
              </Col>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Atrraction_after.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  After
                </Text>
              </Col>
            </Row>
          </Container>
          <Spacer y={2} />
          <Container gap={2}>
            <Row>
              <Col>
                <Text h5>Increase similarity:</Text>
                <Spacer y={1} />

                <Text
                  css={{
                    fontFamily: "$primary",
                    lineHeight: "$xl",
                  }}
                >
                  In the first design, I used the word "post" to write a review, which confuses
                  users. So I changed the word "Post" to "Write" to help users become more familiar
                  with how to write a review.
                </Text>
              </Col>
            </Row>
            <Spacer y={1} />
            <Row justify="center">
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Review_before.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  Before
                </Text>
              </Col>
              <Col>
                <Image
                  css={{ borderRadius: "0.5rem" }}
                  src="/images/Review_after.png"
                  alt=""
                  width={200}
                  height={350}
                />
                <Spacer y={1} />
                <Text h3 css={{ textAlign: "center" }}>
                  After
                </Text>
              </Col>
            </Row>
          </Container>
          <Spacer y={2} />
        </Container>

        {/* Final */}

        <Container
          css={{
            backgroundColor: "$orange",
            paddingTop: "2rem",
            borderRadius: "2rem",
          }}
        >
          <Container sm>
            <Row>
              <Text h2 color="white">
                Final Design
              </Text>
            </Row>
            <Spacer y={2} />

            {/* Main Feat. */}

            <Container gap={2}>
              <Text h3 color="white">
                Main features
              </Text>
              <Spacer y={1} />
              <Grid.Container>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Main/GlobeMockup.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Main/Journal.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Main/AboutMe.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
              </Grid.Container>
            </Container>
            <Spacer y={2} />

            {/* Attraction & Rooms details */}

            <Container gap={2}>
              <Text h3 color="white">
                Attraction & Rooms details
              </Text>
              <Spacer y={1} />

              <Grid.Container>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Hotel/Hotel_review_mockup_1.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Hotel/Hotel_review_mockup_2.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Hotel/Hotel_review_mockup_3.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
              </Grid.Container>
            </Container>
            <Spacer y={2} />

            {/* Diary & Journal */}

            <Container gap={2}>
              <Text h3 color="white">
                Diary & Journal
              </Text>
              <Spacer y={1} />
              <Grid.Container>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Diary_Journal/DairyMockup.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Diary_Journal/DairyMockup2.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Diary_Journal/DairyMockup3.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
              </Grid.Container>
            </Container>

            <Spacer y={2} />

            {/* Review & Searching */}

            <Container gap={2}>
              <Text h3 color="white">
                Review & Searching
              </Text>
              <Spacer y={1} />
              <Grid.Container>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Review/Search.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Review/HotelSearch.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
                <Grid xs={12} sm={4}>
                  <Image
                    css={{ borderRadius: "0.5rem" }}
                    src="/images/Final/Review/WriteReview.png"
                    alt=""
                    width={200}
                    height={350}
                  />
                </Grid>
              </Grid.Container>
            </Container>

            <Spacer y={2} />
          </Container>
        </Container>
        <Spacer y={2} />
        {/* Summary */}

        <Text h3> What I learned </Text>
        <Spacer y={1} />
        <Text
          css={{
            fontFamily: "$primary",
            lineHeight: "$xl",
          }}
        >
          As with all of the previous processes, I've learned about the Lean UX design framework and
          how to make a design meet the requirements in a short amount of time. Start by conducting
          user research, ideation, visual design, and usability study. And I can keep the knowledge
          to use for my career in the future.
        </Text>

        <Spacer y={2} />
      </Container>
      <Container fluid gap={2} css={{ backgroundColor: "$bedge", padding: "2rem 0" }}>
        <Container md>
          <Row>
            <Text h3>Other projects</Text>
          </Row>
          <Spacer y={2} />
          <Grid.Container gap={2}>
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/JustSnap/JustSnapThumbnail.png"
              header="JustSnap"
              route="/justsnap"
              subheader="Google UX Design"
              content=" Responsive website and application provided easy tutorial to follow and reliable how-to guide for anythings."
              period="July 2022"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/Delivery/Hero.png"
              header="Devliery tracking website"
              route="/delivery"
              subheader="Google UX Design"
              content="A responsive website with detailed package tracking from delivery and SMS notifications to keep users up to date on tracking activities."
              period="July 2022"
            />
            <ExperienceProjectHero
              type="complete"
              imgSrc="/images/Menual/Menual_Hero.png"
              header="Menual"
              route="/menual"
              subheader="Google UX Design"
              content="Food delivery application that can customize their orders as much as they want to, similar to ordering foods by themselves at the restaurant."
              period="July 2022"
            />
          </Grid.Container>
          <Spacer y={2} />

          <Row justify="center">
            <HashLink to="/DailyTrip#top">
              <Button id="backToTop" auto light iconRight={<FaChevronUp />}>
                Back to top
              </Button>
            </HashLink>
          </Row>
          <Spacer y={2} />
        </Container>
      </Container>
    </div>
  );
}
