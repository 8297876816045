import { useState } from "react";
import "../pages/css/Navbar.css";
import { Avatar, Button, Container, Navbar, Row, Spacer, Text } from "@nextui-org/react";
import React from "react";
import { useTheme as useNextTheme } from "next-themes";
import { useTheme } from "@nextui-org/react";
import { RiSunFill, RiMoonClearFill } from "react-icons/ri";
import { stack as Menu } from "react-burger-menu";
import { IoMenu } from "react-icons/io5";
import { HashLink } from "react-router-hash-link";
import { RiCloseCircleFill } from "react-icons/ri";

function NavBar() {
  const [visible, setVisible] = useState(true);
  const { setTheme } = useNextTheme();
  const { theme } = useTheme();
  const { isDark } = useTheme();

  const toggleTheme = () => {
    setTheme(isDark ? "light" : "dark");
  };

  var styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "3rem",
      height: "3rem",
      right: "2rem",
      top: "2rem",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "3rem",
      width: "3rem",
      padding: "0.7rem 0 0 0rem",
    },
    bmCross: {},
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "rgb(20, 30, 39)",
      padding: "4rem 2rem",
      fontSize: "1.5rem",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#ffffff",
      padding: "0.8em",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.7)",
    },
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav style={{ zIndex: "500" }}>
      <Container
        className=" d-none d-md-block"
        css={{
          backgroundColor: "$bedge",
          opacity: "0.95",
          width: "100vw",
          maxWidth: "100vw",
          position: "fixed",
          zIndex: "2",
        }}
      >
        <Container sm justify="space-between" alignItems="center">
          <Row align="center" css={{ p: "1rem 0 1rem 0" }}>
            <Row align="center">
              <HashLink smooth to={`/#top`} style={{ textDecoration: "none" }}>
                <Text
                  b
                  css={{
                    fontSize: "1rem",
                    letterSpacing: "$tight",
                  }}
                >
                  Home
                </Text>
              </HashLink>
              <Spacer x={2} />
              <HashLink
                smooth
                to="/#casestudy"
                style={{ textDecoration: "none" }}
                scroll={(el) => scrollWithOffset(el)}
              >
                <Text
                  b
                  css={{
                    fontSize: "1rem",
                    letterSpacing: "$tight",
                  }}
                >
                  Experiences
                </Text>
              </HashLink>
              <Spacer x={2} />

              <HashLink smooth to={`/aboutme#top`} style={{ textDecoration: "none" }}>
                {/* <Button
                    auto
                    light
                    css={{
                      fontSize: "1rem",
                      textTransform: "uppercase",
                      fontWeight: "$bold",
                      letterSpacing: "0.05rem",
                    }}
                  > */}
                <Text
                  b
                  css={{
                    fontSize: "1rem",
                    letterSpacing: "$tight",
                  }}
                >
                  About me
                </Text>
                {/* </Button> */}
              </HashLink>
            </Row>
            <Row justify="flex-end">
              {isDark ? (
                <Avatar
                  color=""
                  squared
                  pointer
                  checked={isDark}
                  onClick={() => toggleTheme()}
                  icon={<RiSunFill color="white" />}
                  css={{ backgroundColor: "rgb(32, 50, 57)" }}
                />
              ) : (
                <Avatar
                  color=""
                  squared
                  pointer
                  bordered
                  checked={isDark}
                  onClick={() => toggleTheme()}
                  icon={<RiMoonClearFill color="white" />}
                  css={{ backgroundColor: "$primary" }}
                />
              )}
            </Row>
          </Row>
        </Container>
      </Container>

      {/* <Navbar
        isBordered
        variant="sticky"
        shouldHideOnScroll
        maxWidth="fluid"
        color="$badge"
        css={{ color: "$primary" }}
      >
        <Navbar.Content
          enableCursorHighlight
          isCursorHighlightRounded
          activeColor="$gray"
          variant="highlight-rounded"
        >
          <HashLink smooth to={`/#top`} style={{ textDecoration: "none" }}>
            <Button>Home</Button>
          </HashLink>
          <HashLink
            smooth
            to="/#casestudy"
            style={{ textDecoration: "none" }}
            scroll={(el) => scrollWithOffset(el)}
          >
            <Button
              auto
              light
              css={{
                fontSize: "1rem",
                textTransform: "uppercase",
                fontWeight: "$bold",
                letterSpacing: "0.05rem",
              }}
            >
              work
            </Button>
          </HashLink>

          <HashLink smooth to={`/aboutme#top`} style={{ textDecoration: "none" }}>
            <Button
              auto
              light
              css={{
                fontSize: "1rem",
                textTransform: "uppercase",
                fontWeight: "$bold",
                letterSpacing: "0.05rem",
              }}
            >
              about me
            </Button>
          </HashLink>
        </Navbar.Content>
        <Navbar.Content>
          {isDark ? (
            <Avatar
              color=""
              squared
              pointer
              checked={isDark}
              onClick={() => toggleTheme()}
              icon={<RiSunFill color="white" />}
              css={{ backgroundColor: "rgb(32, 50, 57)" }}
            />
          ) : (
            <Avatar
              color=""
              squared
              pointer
              bordered
              checked={isDark}
              onClick={() => toggleTheme()}
              icon={<RiMoonClearFill color="white" />}
              css={{ backgroundColor: "$primary" }}
            />
          )}
        </Navbar.Content>
      </Navbar> */}

      <Container
        className="app__navbar d-block d-md-none"
        gap={0}
        css={{ width: "100%", zIndex: "100", backgroundColor: "$bedge", p: "0rem" }}
      >
        <Row>
          <Menu
            styles={styles}
            right
            customBurgerIcon={
              <Container
                gap={0}
                css={{
                  backgroundColor: "$bedge",
                  borderRadius: "999rem",
                  p: "8px",
                  border: "solid 2px $primary",
                }}
                justify="center"
                alignItems="center"
              >
                <IoMenu size={28} />
              </Container>
            }
            customCrossIcon={<RiCloseCircleFill color="white" />}
            isOpen={isMenuOpen}
            onOpen={() => setIsMenuOpen(!isMenuOpen)}
            onClose={() => setIsMenuOpen(false)}
            static
            // noOverlay
          >
            <Row>
              <HashLink to={"/#top"} id="home" onClick={() => setIsMenuOpen(false)}>
                <Text h3 color="white" css={{ textAlign: "end" }}>
                  Home
                </Text>
              </HashLink>
            </Row>
            <Spacer y={1} />
            <Row>
              <HashLink
                to={"/#casestudy"}
                id="work"
                smooth
                onClick={() => setIsMenuOpen(false)}
                scroll={(el) => scrollWithOffset(el)}
              >
                <Text h3 color="white" css={{ textAlign: "end" }}>
                  Work
                </Text>
              </HashLink>
            </Row>

            <Spacer y={1} />
            <Row>
              <HashLink
                smooth
                to={"/aboutme#top"}
                id="aboutme"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text h3 color="white" css={{ textAlign: "end" }}>
                  About me
                </Text>
              </HashLink>
            </Row>

            <Spacer y={1} />

            <Row justify="flex-end">
              {isDark ? (
                <Button onClick={() => toggleTheme()}>
                  <Row align="center">
                    <RiSunFill color="white" size={20} />
                    <Spacer x={1} />
                    <Text b color="white">
                      Light mode
                    </Text>
                  </Row>
                </Button>
              ) : (
                <Button onClick={() => toggleTheme()}>
                  <Row align="center">
                    <RiMoonClearFill color="white" size={20} />
                    <Spacer x={1} />
                    <Text b color="white">
                      Dark mode
                    </Text>
                  </Row>
                </Button>
              )}
            </Row>
          </Menu>
        </Row>
      </Container>
    </nav>
  );
}

export default NavBar;
